<template>
    <div>
      <b-container class="mt-5 mb-5">
        <b-button to="/manage/organisation/locations/create" variant="primary" class="float-right">
          Locatie toevoegen
        </b-button>
        <h1 class="fs-4 font-weight-bold mb-0">Locaties</h1>
        <p class="text-muted">Waar vinden evenementen plaats?</p>

        <template v-if="!locations.length">
          <b-card no-body>
            <b-card-body>
                <div class="text-center text-muted mt-4 mb-4">
                    <font-awesome-icon icon="map-marker" class="mb-2" size="2x" />
                    <div class="text-dark fs-5">Geen locaties gevonden.</div>
                    <div>Voeg een locatie toe voor uw evenementen.</div>
                </div>
            </b-card-body>
          </b-card>
        </template>

        <template v-else>
          <b-list-group class="shadow">
            <b-list-group-item v-for="(location, key) in locations" :key="key" :to="`/manage/organisation/locations/edit/` + location.id">
                <div class="float-left">
                    <font-awesome-icon class="intro-icon mt-1" icon="fa-solid fa-map-marker" size="2x" />
                </div>
                <div class="icon-text">
                    <font-awesome-icon class="arrow-icon float-right" icon="fa-solid fa-chevron-right" />
                    <div>{{ location.name }}</div>
                    <p v-if="location.place" class="text-muted mb-0">{{ location.place }}</p>
                </div>
            </b-list-group-item>
          </b-list-group>
        </template>

      </b-container>
    </div>
  </template>
  
  <script>
    import { mapActions } from 'pinia'
    import { useShopStore } from '@/stores/shop'

    export default {
        metaInfo: {
            title: 'Locaties',
        },
        components: {
        },
        data() {
            return {
              locations: [],
            }
        },
        computed: {},
        methods: {
          ...mapActions(useShopStore, ['setLoading']),
          getLocations: function() {
            this.setLoading(true);
            this.$axios.get("https://api.tixgo.nl/locations")
              .then( response => {
                this.locations = response.data;
                this.setLoading(false);
            }).catch(response => { console.log(response); });
          }
        },
        created() {
          this.getLocations();
        }
    }
</script>
  
<style scoped>
.intro-icon{
    background-color:#CA355B;
    font-size: 25px;
    padding:8px;
    color:#fff;
    border-radius:10px;
    height:25px;
    width:25px;
}
.icon-text{
    padding-left:60px;
}
.arrow-icon{
    float:right;
    top:50%;
    margin-top:15px;
    padding-left:15px;
    color:#999;
}
</style>